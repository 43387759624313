import React from "react"
import Button from "../../Button"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"

const Revolutionize = ({ icon, heading, text, button_text, button_url }) => {
  return (
    <section className="w-full">
      <div className="container mx-auto px-6 lg:px-8 flex flex-col md:flex-row py-12 md:py-16 lg:py-20 xl:py-24 md:space-x-8 lg:space-x-16 justify-between">
        <div className="w-full md:w-1/2 flex flex-col lg:flex-row lg:space-x-4 xl:space-x-8">
          <div className="w-16 h-16 lg:w-24 lg:h-24">
            <GatsbyImage alt="Icon" placeholder="blurred" image={icon} />
          </div>
          <div>
            <h2 className="font-semibold leading-tight text-4xl md:text-5xl tracking-tight max-w-sm m-0 mt-4 lg:mt-0">
              {heading}
            </h2>
          </div>
        </div>
        <div className="w-full md:w-1/2 flex mt-6 md:mt-0">
          <div className="flex flex-col justify-center md:ml-auto">
            <p className="m-0 mb-8 max-w-xl">
              <PrismicRichText field={text} />
            </p>
            <Button size="md" link={button_url}>
              {button_text}
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Revolutionize
