import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import Button from "../components/Button"
import Header from "../components/CareerPage/Header"
import Revolutionize from "../components/CareerPage/Revolutionize"
import Culture from "../components/CareerPage/Culture"
import Values from "../components/CareerPage/Values"
import Benefits from "../components/CareerPage/Benefits"
import Ratings from "../components/CareerPage/Ratings"
import { formatPrismicLang } from "../utils/formatPrismicLang"

const CareersPage = ({ data }) => {
  const prismicNode = data.allPrismicCareersPage.edges.slice(0, 1).pop().node
  const careersData = prismicNode.data
  const lang = formatPrismicLang(prismicNode)

  const header = careersData.header[0]
  const revolutionize = careersData.revolutionize[0]
  const culture = careersData.culture[0]
  const ratings = careersData.ratings[0]

  return (
    <Layout language={lang}>
      <Header
        heading={header.heading.text}
        text={header.text.richText}
        button_text={header.button_text}
        button_link={header.button_link.url}
        image_1={header.image.gatsbyImageData}
        image_2={header.image_2.gatsbyImageData}
        image_3={header.image_3.gatsbyImageData}
        image_4={header.image_4.gatsbyImageData}
        image_5={header.image_5.gatsbyImageData}
      />
      <Revolutionize
        heading={revolutionize.heading.text}
        button_text={revolutionize.button_text}
        button_url={revolutionize.button_link.url}
        icon={revolutionize.icon.gatsbyImageData}
        text={revolutionize.text.richText}
      />
      <Culture
        heading={culture.heading.text}
        text_1={culture.text_1.richText}
        text_2={culture.text_2.richText}
        image_1={culture.image_1.gatsbyImageData}
        image_2={culture.image_2.gatsbyImageData}
        image_3={culture.image_3.gatsbyImageData}
        image_4={culture.image_4.gatsbyImageData}
        image_5={culture.image_5.gatsbyImageData}
      />
      <Values
        heading={careersData.values_heading.text}
        values={careersData.values}
      />
      <Benefits
        heading={careersData.benefits_heading.text}
        benefits={careersData.benefits}
      />
      <Ratings
        heading={ratings.heading.text}
        text={ratings.text.richText}
        button_link={ratings.button_link.url}
        button_text={ratings.button_text}
        image={ratings.image.gatsbyImageData}
        image_link={ratings.image_link.url}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query CareersQuery($lang: String) {
    allPrismicCareersPage(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          lang
          data {
            benefits {
              text {
                richText
              }
              title {
                text
              }
            }
            benefits_heading {
              text
            }
            values_heading {
              text
            }
            values {
              text {
                richText
              }
              title {
                text
              }
            }
            revolutionize {
              button_text
              button_link {
                url
              }
              heading {
                text
              }
              icon {
                gatsbyImageData(width: 100)
              }
              text {
                richText
              }
            }
            ratings {
              button_link {
                url
              }
              button_text
              heading {
                text
              }
              image {
                gatsbyImageData(height: 330)
              }
              image_link {
                url
              }
              text {
                richText
              }
            }
            header {
              text {
                richText
              }
              button_link {
                url
              }
              button_text
              heading {
                text
              }
              image {
                gatsbyImageData(height: 264)
              }
              image_2 {
                gatsbyImageData(height: 264)
              }
              image_3 {
                gatsbyImageData(height: 264)
              }
              image_4 {
                gatsbyImageData(height: 264)
              }
              image_5 {
                gatsbyImageData(height: 264)
              }
            }
            culture {
              heading {
                text
              }
              text_1 {
                richText
              }
              text_2 {
                richText
              }
              image_5 {
                gatsbyImageData(height: 445)
              }
              image_4 {
                gatsbyImageData(height: 445)
              }
              image_3 {
                gatsbyImageData(height: 256)
              }
              image_2 {
                gatsbyImageData(height: 256)
              }
              image_1 {
                gatsbyImageData(height: 256)
              }
            }
          }
        }
      }
    }
  }
`

export default CareersPage
