import React from "react"
import ValueItem from "./ValueItem"

const Values = ({ heading, values }) => {
  const firstThreeValues = values.slice(0, 3)
  const lastTwoValues = values.slice(3, 5)

  const headingSplit = heading.split(" ")
  const lastHeadingPart = headingSplit.pop()

  return (
    <section className="w-full">
      <div className="container mx-auto px-8  pt-12 pb-16 md:pt-16 md:pb-20 lg:pt-20 lg:pb-24">
        <h2 className="font-semibold text-4xl md:text-5xl m-0 mb-4 md:mb-6 lg:mb-8">
          {headingSplit.map(headingPart => (
            <>
              {headingPart}
              {` `}
            </>
          ))}
          <span className="bg-primary text-white px-2">{lastHeadingPart}</span>
        </h2>
        <div className="flex flex-col items-center">
          <div className="grid col-span-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-4 md:gap-y-0">
            {firstThreeValues.map((value, index) => (
              <ValueItem
                key={index}
                text={value.text.richText}
                heading={value.title.text}
                className={index === 2 ? "md:col-span-2 lg:col-span-1" : ""}
              />
            ))}
          </div>
          <div className="grid col-span-1 md:grid-cols-2 gap-x-12 mt-4 md:mt-8 gap-y-4 md:gap-y-0">
            {lastTwoValues.map((value, index) => (
              <ValueItem
                key={index}
                text={value.text.richText}
                heading={value.title.text}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Values
