import React from "react"
import { PrismicRichText } from "@prismicio/react"

const BenefitItem = ({ heading, text }) => {
  return (
    <div>
      <h3 className="text-xl my-2  md:my-3">{heading}</h3>
      <p className="m-0">
        <PrismicRichText field={text} />
      </p>
    </div>
  )
}

export default BenefitItem
