import React from "react"
import BenefitItem from "./BenefitItem"

const Benefits = ({ heading, benefits }) => {
  const headingSplit = heading.split(" ")
  return (
    <section className="w-full bg-gray-200 pt-12 pb-16 md:pt-16 md:pb-20 lg:pt-20 lg:pb-24 xl:pt-24 xl:pb-28">
      <div className="container mx-auto px-8">
        <h2 className="font-semibold text-4xl md:text-5xl m-0 mb-4 md:mb-6">
          {headingSplit[0]}
          {` `}
          <span className="bg-primary text-white px-2">{headingSplit[1]}</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-16 lg:gap-x-20 xl:gap-x-28 gap-y-4 md:gap-y-8 lg:gap-y-12">
          {benefits.map((benefit, index) => (
            <BenefitItem
              key={index}
              text={benefit.text.richText}
              heading={benefit.title.text}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Benefits
