import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import Button from "../../Button"

const Ratings = ({
  heading,
  text,
  button_text,
  button_link,
  image,
  image_link,
}) => {
  return (
    <section className="w-full">
      <div className="container mx-auto px-8 flex flex-col md:flex-row py-12 md:py-16 lg:py-20 xl:py-24 md:space-x-20 justify-center">
        <div className="flex lg:justify-end">
          <div className="flex flex-col justify-center">
            <h2 className="font-semibold text-4xl md:text-5xl m-0 mb-4 md:mb-6">
              {heading}
            </h2>
            <p className="m-0 mb-4 md:mb-8 max-w-lg">
              <PrismicRichText field={text} />
            </p>
            <Button link={button_link}>{button_text}</Button>
          </div>
        </div>
        <div className="mt-10 md:mt-0">
          <a href={image_link} target="_blank" rel="noopener" rel="noreferrer">
            <div className="h-56 w-48 md:h-80 md:w-72">
              <GatsbyImage
                alt="Top Company Kununu"
                loading="lazy"
                placeholder="dominantColor"
                image={image}
              />
            </div>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Ratings
