import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"

const Culture = ({
  heading,
  text_1,
  text_2,
  image_5,
  image_4,
  image_3,
  image_2,
  image_1,
}) => {
  const headingSplit = heading.split(" ")
  return (
    <section className="w-full bg-primary-hover pt-12 pb-16 md:pt-16 md:pb-20 lg:pt-20 lg:pb-24 xl:pt-24 xl:pb-28">
      <div className="container mx-auto px-6 lg:px-8">
        <h2 className="text-white font-semibold text-4xl md:text-5xl m-0 mb-4 md:mb-6">
          {headingSplit[0]}{" "}
          <span className="bg-primary px-2">{headingSplit[1]}</span>
        </h2>
        <div className="text-white grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-6 lg:gap-8">
          <div className="m-0">
            <PrismicRichText field={text_1} />
          </div>
          <div className="m-0">
            <PrismicRichText field={text_2} />
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 lg:gap-8 mt-6  md:mt-8 lg:mt-12">
          <GatsbyImage
            alt="Team"
            loading="lazy"
            placeholder="dominantColor"
            className="rounded-xl"
            image={image_1}
          />
          <GatsbyImage
            alt="Team"
            loading="lazy"
            placeholder="dominantColor"
            className="rounded-xl"
            image={image_2}
          />
          <GatsbyImage
            alt="Team"
            loading="lazy"
            placeholder="dominantColor"
            className="rounded-xl col-span-2 sm:col-span-1"
            image={image_3}
          />
        </div>
        <div className="mt-4 lg:mt-8 grid grid-cols-2 gap-4 lg:gap-8">
          <GatsbyImage
            alt="Team"
            loading="lazy"
            placeholder="dominantColor"
            className="rounded-xl"
            image={image_4}
          />
          <GatsbyImage
            alt="Team"
            loading="lazy"
            placeholder="dominantColor"
            className="rounded-xl"
            image={image_5}
          />
        </div>
      </div>
    </section>
  )
}

export default Culture
