import React from "react"
import { PrismicRichText } from "@prismicio/react"

const ValueItem = ({ heading, text, className }) => {
  return (
    <div className={`flex flex-col md:items-center ${className}`}>
      <h3 className="md:text-center font-semibold text-xl md:text-2xl xl:text-3xl my-2 md:my-4">
        {heading}
      </h3>
      <p className="m-0 md:max-w-xs md:text-center">
        <PrismicRichText field={text} />
      </p>
    </div>
  )
}

export default ValueItem
